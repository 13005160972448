<template>
  <v-layout class="red-menu">
    <div>
      {{
        $store.state.student.examination
          ? $store.state.student.examination.name
          : ""
      }}
    </div>
    <div class="ml-1" v-if="$store.state.student.subjectName">
      - {{ $store.state.student.subjectName }}
    </div>
    <v-spacer></v-spacer>
    <template v-if="examination">
      <div v-if="examination.endTime">
        {{ $t("thoi_gian_con_lai") }}: {{ remaining }}
      </div>
      <div v-else-if="examination.startExamTime">
        {{ $t("thoi_gian_da_lam") }}: {{ time }}
      </div>
    </template>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import request from "@/utils/request";

export default {
  props: {
    items: { type: Array, default: () => [] },
  },
  data: () => ({
    time: undefined,
    remaining: undefined,
    intervalTime: undefined,
    delta: 0,
  }),
  computed: {
    ...mapState("student", ["examination"]),
  },

  async mounted() {
    const { data: timeServer } = await request({
      url: "/time",
      method: "get",
    });
    this.delta = dayjs(timeServer).diff(dayjs(), "s");
    this.intervalTime = setInterval(() => {
      this.getTime();
    }, 1000);
  },

  destroyed() {
    if (this.intervalTime) {
      clearInterval(this.intervalTime);
    }
  },

  methods: {
    getDuration(time1, time2) {
      if (time1 < time2) {
        time1 = time2;
      }
      const seconds = time1.diff(time2, "s");
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },
    getTime() {
      if (this.examination) {
        const now = dayjs().add(this.delta, "s");
        if (this.examination.startExamTime) {
          this.time = this.getDuration(
            now,
            dayjs(this.examination.startExamTime)
          );
        }
        if (this.examination.endTime) {
          this.remaining = this.getDuration(
            dayjs(this.examination.endTime),
            now
          );
        }
      } else {
        this.time = undefined;
        this.remaining = undefined;
      }
    },
  },
};
</script>

<style scoped>
.red-menu {
  min-height: 67px;
  background-color: #cf1627;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
</style>
