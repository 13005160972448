import { render, staticRenderFns } from "./RedMenu.vue?vue&type=template&id=6abff75e&scoped=true&"
import script from "./RedMenu.vue?vue&type=script&lang=js&"
export * from "./RedMenu.vue?vue&type=script&lang=js&"
import style0 from "./RedMenu.vue?vue&type=style&index=0&id=6abff75e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6abff75e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VLayout,VSpacer})
